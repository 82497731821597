import { AxiosInstance } from "axios";
import { TokenHelper } from "../../types";

// All the paths for this api domain.
export const coreBankingPaths = {
  getAccounts: () => `/core-banking/accounts`,
  getShares: () => `/core-banking/account-shares`,
  locateCIF: () => `/core-banking/cif`,
};

class CoreBankingApi {
  api: AxiosInstance;
  tokenHelper: TokenHelper;

  constructor(api: AxiosInstance, tokenHelper: TokenHelper) {
    this.api = api;
    this.tokenHelper = tokenHelper;
  }

  getAccounts = ({
    coreBankingConfigurationUUID,
    tin,
    birthday,
  }: {
    coreBankingConfigurationUUID: string;
    tin: string;
    birthday?: string;
  }) => {
    return this.api.post(
      coreBankingPaths.getAccounts(),
      {
        coreBankingConfigurationUUID,
        tin,
        birthday,
      },
      {
        headers: {
          Authorization: this.tokenHelper.formatAuthHeader(
            this.tokenHelper.getJwtToken()
          ),
        },
      }
    );
  };

  /**
   * Retrieve all shares a person has in a given core.
   */
  getShares = ({
    coreBankingConfigurationUUID,
    tin,
    birthday,
    accountId,
  }: {
    coreBankingConfigurationUUID: string;
    tin?: string;
    birthday?: string;
    accountId?: string;
  }) => {
    return this.api.post(
      coreBankingPaths.getShares(),
      {
        coreBankingConfigurationUUID,
        tin,
        birthday,
        accountId,
      },
      {
        headers: {
          Authorization: this.tokenHelper.formatAuthHeader(
            this.tokenHelper.getJwtToken()
          ),
        },
      }
    );
  };

  // locate customer information file.
  // POST /core-banking/cif
  locateCIF = ({
    coreBankingConfigurationUUID,
    firstName,
    lastName,
    tin,
    birthday,
  }: {
    coreBankingConfigurationUUID: string;
    firstName?: string;
    lastName?: string;
    tin: string;
    birthday?: string;
  }) => {
    return this.api.post(
      coreBankingPaths.locateCIF(),
      {
        coreBankingConfigurationUUID,
        firstName,
        lastName,
        tin,
        birthday,
      },
      {
        headers: {
          Authorization: this.tokenHelper.formatAuthHeader(
            this.tokenHelper.getJwtToken()
          ),
        },
      }
    );
  };
}

export default CoreBankingApi;
