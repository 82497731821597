//************************************************************************ /
// ALGOLIA AUTOCOMPLETE SEARCH
// Connects our app to Algolia search using our app and search API keys.
// Can be further configured by passing widgets into InstantSearch wrapper.
// Read documentation at https://www.algolia.com/doc/api-reference/widgets/instantsearch/react/.
//************************************************************************ /

import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import { AutoCompleteWidget } from "./widgets";
import { useGA } from "@dreambigger/shared/src/hooks";

type AlgoliaAutoCompleteSearchProps = {
  urlPathname: string;
  indexName: string;
  className?: string;
  placeholder?: string;
  filters?: string;
  size?: any;
};

// Define searchClient with cotribute's app and search api keys for our Algolia account.
const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_KEY_ID || "",
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || ""
);

// Warn developers about missing ENV variables.
if (process.env.NEXT_PUBLIC_ALGOLIA_APP_KEY_ID === "") {
  console.warn(
    "Algolia Search Bar Error: Missing ENV Variable: NEXT_PUBLIC_ALOGLIA_APP_KEY_ID"
  );
}
if (process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY === "") {
  console.warn(
    "Algolia Search Bar Error: Missing ENV Variable: NEXT_PUBLIC_ALOGLIA_SEARCH_API_KEY"
  );
}

// COMPONENT ------------------------------
const AlgoliaAutoCompleteSearch = ({
  urlPathname,
  indexName,
  className,
  placeholder,
  filters,
  size,
}: AlgoliaAutoCompleteSearchProps) => {
  // Connect Google Analytics and log clicks/focus to the component.
  const googleAnalytics = useGA();
  const handleClick = () => {
    googleAnalytics.event({
      category: "Main Nav - Search",
      action: "Clicked",
      label: "Autocomplete",
    });
  };
  const handleFocus = () => {
    googleAnalytics.event({
      category: "Main Nav - Search",
      action: "Active",
      label: "Autocomplete",
    });
  };
  return (
    <div onClick={handleClick} onFocus={handleFocus}>
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        {/* CONFIGURE - Documentation of available parameters: https://www.algolia.com/doc/api-reference/search-api-parameters/ */}
        {/* Example Filter: (type:event OR type:fundraiser OR type:community OR type:collection) AND organizationId:${community.organization.id} */}
        <Configure filters={filters} hitsPerPage={8} />

        {/* INPUT COMPONENT - This is a custom component built using Antd Design Autocomplete. Documentation: https://www.algolia.com/doc/api-reference/widgets/autocomplete/react/?client=jsx */}
        <AutoCompleteWidget
          urlPathname={urlPathname}
          className={className}
          placeholder={placeholder || "Search"}
          size={size || "medium"}
        />
        {/* --- Additional widgets can be added here */}
      </InstantSearch>
    </div>
  );
};

export default AlgoliaAutoCompleteSearch;
