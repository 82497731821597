//************************************************************************ /
// ALGOLIA AUTOCOMPLETE WIDGET
// A custom-built search component built using Antd Autocomplete wrapping an Antd Input (documentation - https://ant.design/components/auto-complete/).
// Read Algolia's documentation at https://www.algolia.com/doc/api-reference/widgets/autocomplete/react/?client=jsx.
// Must be passed into Algolia's <InstantSearch> component to function. Can be further configured with Algolia's <Configure> component, also passed into <InstantSearch>.
//************************************************************************ /

import {
  AutoComplete,
  Form,
  Input,
} from "@dreambigger/design-system/src/components";
import { connectAutoComplete } from "react-instantsearch-dom"; // Types added with https://yarnpkg.com/package/@types/react-instantsearch-dom
import { useState, useMemo } from "react";
import { AutocompleteProvided } from "react-instantsearch-core";
import useGA from "@dreambigger/shared/src/hooks/use-ga";

interface AutoCompleteProps extends AutocompleteProvided {
  urlPathname: string;
  className?: string;
  placeholder?: string;
  size?: any;
}

// COMPONENT -------------------------------------------
const AutoCompleteComponent = ({
  hits,
  currentRefinement,
  refine,
  urlPathname,
  className,
  placeholder,
  size,
}: AutoCompleteProps) => {
  // Connect Google Analytics
  const googleAnalytics = useGA();
  // Store current search query
  const [query, setQuery] = useState(currentRefinement);
  // When hits changes, submit a search list. Hits is automatically refined by refine function passed into "onChange" in props.
  // Each option returned includes a value (string) and a label (a JSX.element)
  const searchList = useMemo(
    () =>
      hits?.map((hit, index) => {
        return {
          value: `${index}`,
          slug: hit.title,
          label: (
            <div className="gray-7">
              <span className="gray-9">{hit.title}</span>
            </div>
          ),
        };
      }) || [],
    [hits]
  );

  // Search can be triggered either by selecting a value from the dropdown list,
  const searchCallback = (slug?: string) => {
    const url = new URL(window.location.href);

    // If a slug is passed in, update the query (input value) to reflect this.
    if (slug) {
      setQuery(slug);
    }
    url.pathname = urlPathname;
    // Update or append the searchText query parameter
    if (url.searchParams.has("searchText")) {
      url.searchParams.set("searchText", slug || query);
    } else {
      url.searchParams.append("searchText", slug || query);
    }

    googleAnalytics.event({
      category: "Main Nav - Search",
      action: "Query",
      label: "Autocomplete",
    });

    // Update the current URL
    window.location.href = url.toString();
  };

  // Upon selecting a hit from the dropdown menu, initiate a search for that hit.
  const handleSelect = (
    key: string,
    hit: { value: string; slug: string; label: JSX.Element }
  ) => {
    searchCallback(hit.slug);
  };

  return (
    <Form
      style={{ height: "40px" }}
      onFinish={(formValue) => {
        searchCallback(formValue.search);
      }}
    >
      <Form.Item>
        <AutoComplete
          options={searchList}
          searchValue={currentRefinement}
          value={query}
          className={className}
          onChange={() => refine(query)}
          onSearch={setQuery}
          onSelect={handleSelect}
          defaultActiveFirstOption={false}
          // Hide dropdown until characters are typed and there are hits.
          open={query.length > 0 && searchList.length > 0}
          // Fallback just in case of empty results (ideally should never show because of "open" paramater above).
          notFoundContent="No results found"
        >
          <Input.Search
            style={{
              padding: "5px 20px !important",
            }}
            placeholder={placeholder}
            size={"large"}
            onSearch={searchCallback}
          />
        </AutoComplete>
      </Form.Item>
    </Form>
  );
};

// Export the widget using the connector provided by Algolia
export const AutoCompleteWidget = connectAutoComplete<AutoCompleteProps>(
  AutoCompleteComponent
);
