import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  CopyToClipboard,
} from "../../atoms";
import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Group } from "@dreambigger/design-system/src";
import InspireAnalytics, {
  InspireAnalyticsHelper,
  InspireAnalyticEvents,
} from "@dreambigger/shared/src/utils/inspire-analytics-helper";
import { useGA, useSegment, useMixpanel } from "@dreambigger/shared/src/hooks";

interface ShareBarProps {
  facebook?: boolean;
  twitter?: boolean;
  email?: boolean;
  clipboard?: boolean;
  rsvpUrl?: string;
  buttonClass?: string;
  shareMessageSubject?: string | undefined;
  shareMessageBody?: string | undefined;
  labeledButtons?: boolean;
  rectangularButtons?: boolean;
  liftOnHover?: boolean;
}

// DEFAULT SHARE MESSAGE BODY IF NOT PASSED IN
// Dev Note: Preserve line and tab spacing between these lines because of template literal ---
const defaultShareBody = `Hello! I wanted to share this link with you because I thought you might find it interesting. 
  
Here is the link if you want to check it out:`;

const ShareBar = ({
  facebook = true,
  twitter = true,
  email = true,
  clipboard = true,
  rsvpUrl,
  shareMessageSubject = `Check this out!`,
  shareMessageBody = defaultShareBody,
  buttonClass = "h-7 w-7",
  labeledButtons,
  rectangularButtons,
  liftOnHover,
}: ShareBarProps) => {
  // Set up analytics
  const mixpanel = useMixpanel();
  const router = useRouter();
  const segment = useSegment();
  const googleAnalytics = useGA();

  const shareUrl = process?.browser ? window.location.href.split("?")[0] : "";

  const handleClick = (shareType: string) => {
    // Handle the "share" mixpanel + segment event if user clicks on any of the share icons.
    InspireAnalytics.track({
      eventName: InspireAnalyticEvents.SHARE,
      properties: {
        "Page Type": InspireAnalyticsHelper.getPageType(router.route),
        "Share Type": shareType,
      },
      mixpanel,
      segment,
    });
    // Also report click to Google Analytics
    googleAnalytics.event({
      category: "External Collection Share",
      action: "Select",
      label: shareType,
      value: 1,
    });
  };

  // ------------------------------------------------------------------------------------------------------------------
  //   1  LABELED BUTTONS
  // -----------------------------------------------------------------------------------------------------------------
  if (labeledButtons) {
    return (
      <>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');
        </style>
        <Group xs="horizontal" spacing={4} className="justify-center">
          {/* Facebook Button */}
          {facebook && (
            <FacebookShareButton
              url={shareUrl}
              aria-label="share on facebook"
              onClick={() => handleClick("facebook")}
            >
              <div
                className={`flex pv-1 pl-3 justify-evenly ${
                  liftOnHover && "subtleLift"
                }`}
                style={{
                  backgroundColor: "#3b5998",
                  borderRadius: "7px",
                  width: "132px",
                }}
              >
                <p
                  className="white fwb ttu mb-0 f-3 tc usn mv-auto"
                  style={{ fontFamily: "Roboto" }}
                >
                  Share On
                </p>
                <FacebookIcon size="50px" />
              </div>
            </FacebookShareButton>
          )}

          {/* Twitter Button */}
          {twitter && (
            <TwitterShareButton
              url={shareUrl}
              aria-label="share on twitter"
              onClick={() => handleClick("twitter")}
            >
              <div
                className={`flex pv-1 pl-3 justify-evenly ${
                  liftOnHover && "subtleLift"
                }`}
                style={{
                  backgroundColor: "#04abed",
                  borderRadius: "7px",
                  width: "132px",
                }}
              >
                <p
                  className="white fwb ttu mb-0 f-3 tc usn mv-auto"
                  style={{ fontFamily: "Roboto" }}
                >
                  TWEET
                </p>
                <TwitterIcon size="50px" />
              </div>
            </TwitterShareButton>
          )}

          {/* Email Button
           *** If a rsvpUrl is passed in, this should launch the rsvp flow instead.
           */}

          {email && rsvpUrl ? (
            // - with rsvpUrl (launches rsvp flow)
            <Link href={rsvpUrl}>
              <div
                onClick={() => handleClick("invite")}
                className={`flex pv-1 pl-3 justify-evenly pointer ${
                  liftOnHover ? "subtleLift" : ""
                }`}
                style={{
                  backgroundColor: "#7f7f7f",
                  borderRadius: "7px",
                  width: "132px",
                }}
              >
                <p
                  className="white fwb ttu mb-0 f-3 tc usn mv-auto"
                  style={{ fontFamily: "Roboto" }}
                >
                  INVITE
                </p>
                <EmailIcon size="50px" />
              </div>
            </Link>
          ) : (
            // - without rsvpUrl (default)
            <EmailShareButton
              subject={shareMessageSubject}
              body={shareMessageBody || defaultShareBody}
              url={shareUrl}
              aria-label="share through email"
            >
              <div
                onClick={() => handleClick("email")}
                className={`flex pv-1 pl-3 justify-evenly ${
                  liftOnHover && "subtleLift"
                }`}
                style={{
                  backgroundColor: "#7f7f7f",
                  borderRadius: "7px",
                  width: "132px",
                }}
              >
                <p
                  className="white fwb ttu mb-0 f-3 tc usn mv-auto"
                  style={{ fontFamily: "Roboto" }}
                >
                  EMAIL
                </p>
                <EmailIcon size="50px" />
              </div>
            </EmailShareButton>
          )}

          {/* Clipboard Button */}
          {clipboard && (
            <div
              onClick={() => handleClick("clipboard")}
              className={`flex pv-1 pl-3 justify-evenly ${
                liftOnHover && "subtleLift"
              }`}
              style={{
                backgroundColor: "gray",
                borderRadius: "7px",
                width: "132px",
              }}
            >
              <p
                className="white fwb ttu mb-0 f-3 tc usn mv-auto"
                style={{ fontFamily: "Roboto" }}
              >
                COPY
              </p>
              <CopyToClipboard
                text={shareUrl}
                className="h-100 w-7"
                style={{
                  backgroundColor: "gray",
                }}
                iconClassName="f-4 sm_f-6 white"
                aria-label="copy url"
              />
            </div>
          )}
        </Group>
      </>
    );
  }

  // ------------------------------------------------------------------------------------------------------------------
  //   2  NON-LABELED BUTTONS (DEFAULT)
  // ------------------------------------------------------------------------------------------------------------------
  return (
    <Group xs="horizontal" spacing={4} className="justify-center">
      {/* Facebook Button */}
      {facebook && (
        <FacebookShareButton
          url={shareUrl}
          aria-label="share on facebook"
          onClick={() => handleClick("facebook")}
        >
          <FacebookIcon
            className={`${buttonClass} ${liftOnHover && "subtleLift"}`}
            round={!rectangularButtons}
          />
        </FacebookShareButton>
      )}

      {/* Twitter Button */}
      {twitter && (
        <TwitterShareButton
          url={shareUrl}
          aria-label="share on twitter"
          onClick={() => handleClick("twitter")}
        >
          <TwitterIcon
            className={`${buttonClass} ${liftOnHover && "subtleLift"}`}
            round={!rectangularButtons}
          />
        </TwitterShareButton>
      )}

      {/* Email Button
       *** If a rsvpUrl is passed in, this should launch the rsvp flow instead. */}
      {email && rsvpUrl ? (
        <div onClick={() => handleClick("invite")}>
          <Link href={rsvpUrl}>
            <EmailIcon
              className={`${buttonClass} pointer ${
                liftOnHover ? "subtleLift" : ""
              }`}
              round={!rectangularButtons}
            />
          </Link>
        </div>
      ) : (
        <div onClick={() => handleClick("email")}>
          <EmailShareButton
            subject={shareMessageSubject}
            body={shareMessageBody || defaultShareBody}
            url={shareUrl}
            aria-label="share through email"
          >
            <EmailIcon
              className={`${buttonClass} ${liftOnHover && "subtleLift"}`}
              round={!rectangularButtons}
            />
          </EmailShareButton>
        </div>
      )}

      {/* Clipboard Button */}
      {clipboard && (
        <CopyToClipboard
          text={shareUrl}
          className={`${buttonClass} ${liftOnHover && "subtleLift"}`}
          style={{
            backgroundColor: "gray",
            animation: "none",
            transition: "none",
            overflow: "auto",
          }}
          round={!rectangularButtons}
          iconClassName="f-5 white"
          aria-label="copy url"
          onClick={() => handleClick("clipboard")}
        />
      )}
    </Group>
  );
};

export default ShareBar;
